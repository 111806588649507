import { ModalBase } from "../modal_base";

export class OperatorSupplierListUpdateModal extends ModalBase {

    constructor() {
        super('operator_supplier_list_update_modal');
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const el = $(e.currentTarget);
            let data = additionalData;
            if(el.data('btn-action')) {
                data.modalClickedAction = el.data('btn-action');
            }

            const event = new CustomEvent('OperatorSupplierListUpdateAjax', {
                detail: data
            });
            event.modalId = this.MODAL_ID;
            event.modalTriggerButton = $modalTriggerBtn;
            window.dispatchEvent(event);
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }

    async show($modalTriggerBtn, additionalData) {
        try {
            await this.setupDOM($modalTriggerBtn, additionalData);
            await this.setupModalEvents($modalTriggerBtn, additionalData);
            const shouldOpen = await this.setupModalView($modalTriggerBtn, additionalData);
            if (shouldOpen === false) {
                return;
            }

            if (additionalData.text) {
                this.MODAL_CONTAINER_EL.find('.modal-text').text(additionalData.text);
            }

            const modalAdditionalContent = this.MODAL_CONTAINER_EL.find('.modal__additional-content');
            if (modalAdditionalContent.length === 1) {
                // start the additional content from fresh to ensure we have no left-over messages
                modalAdditionalContent.html("");
                const message = this.getWarningMessage(additionalData);
                if (message && message.length > 0) {
                    modalAdditionalContent.append(`<div>${message}</div>`);
                }
            }

            MicroModal.show(this.MODAL_ID, {
                disableScroll: true,
                onClose: () => this.onClose()
            });
        } catch (err) {
            console.warn('Unable to display modal.', err);
        }
    }

    getWarningMessagePart(count, tbl) {
        if (!count || count === '0' || count === '-') {
            return '';
        }

        const related = {
            i: this.getRelatedName(tbl),
            ii: `${this.getRelatedName(tbl)}(s)`,
            lower_i: this.getRelatedName(tbl).toLowerCase(),
            lower_ii: `${this.getRelatedName(tbl).toLowerCase()}(s)`
        }

        return `<p>
            This supplier is currently assigned to ${count} ${related.ii}.
            <br/>
            The associated ${related.lower_ii} will no longer have a supplier.
        </p>`;
    }

    getWarningMessage(additionalData) {
        let message = "",
            countData = [],
            vehCount = additionalData.count_veh !== '-' ? parseInt(additionalData.count_veh) : 0,
            cosCount = additionalData.count_cos !== '-' ? parseInt(additionalData.count_cos) : 0,
            docCount = additionalData.count_doc !== '-' ? parseInt(additionalData.count_doc) : 0;

        if (vehCount > 0) {
            countData.push({tbl: "veh", count: vehCount})
        }

        if (cosCount > 0) {
            countData.push({tbl: "cos", count: cosCount})
        }

        if (docCount > 0) {
            countData.push({tbl: "doc", count: docCount})
        }

        $.each(countData, (index, data) => {
            // do we need to add a comma or and
            if (index > 0) {
                if (index < countData.length - 1) {
                    message += ", ";
                } else {
                    message += " and ";
                }
            }

            // add the count and the record name to the message e.g. 10 assets
            message += `${data.count} ${this.getRelatedName(data.tbl)}${data.count > 1 ? "s" : ""}`;
        });

        if (message.length > 0) {
            return `<p>
                This supplier is currently assigned to ${message}.
                <br/>
                The associated record(s) will no longer have a supplier.
            </p>`;
        }

        return "";
    }

    getRelatedName(tbl) {
        switch (tbl) {
            case 'veh':
                return 'Asset';
            case 'cos':
                return 'Cost';
            case 'doc':
                return 'Document';
            default:
                return '';
        }
    }
}
